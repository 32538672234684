// https://github.com/championswimmer/vuex-persist

import VuexPersistence from 'vuex-persist';

const key = 'ADJ_VUEX';

export default ({ store, $bugsnag }) => {
  try {
    new VuexPersistence({
      key: `${key}_PREFS`,
      reducer: (state) => ({
        preferences: {
          detailFromMap: state.preferences.detailFromMap,
          dialogAboutViewed: state.preferences.dialogAboutViewed,
        },
      }),
    }).plugin(store);

    new VuexPersistence({
      key: `${key}_DATA`,
      reducer: (state) => ({
        transferData: {
          currentSearch: state.transferData.currentSearch,
          searchResults: state.transferData.searchResults,
        },
      }),
    }).plugin(store);
  } catch (e) {
    $bugsnag.notify(e);
  }
};
