import shareMutations, { BroadcastChannelStrategy, LocalStorageStratery } from 'vuex-shared-mutations';

const key = 'ADJ_VUEX_SYNC';

const predicate = [
  'authToken/auth', 'authToken/token', 'authToken/updateEmailToken', 'authToken/await',
  'firebase/userUid', 'firebase/access', 'firebase/user', 'firebase/pending', 'firebase/updateConfirm', 'firebase/updateUserNotMatch',
  'map/geoToken', 'map/mapToken', 'map/mapTokenExp',
  'preferences/user', 'preferences/setNotification', 'preferences/setUserPrefs', 'preferences/setPrefs', 'preferences/dialogAboutViewed',
  'syncTab/dialogRestoreAccount', 'syncTab/userControl',
];

export default ({ store, $bugsnag }) => {
  // https://github.com/xanf/vuex-shared-mutations
  // 1.0.2 : Override de la stratégie par default. (Erreur dans la doc)
  const createDefaultStrategy = () => {
    if (BroadcastChannelStrategy.available()) {
      return new BroadcastChannelStrategy({ key });
    }

    if (LocalStorageStratery.available()) {
      return new LocalStorageStratery({ key });
    }

    $bugsnag.notify(new Error('No vuex sync strategies available'));
    return false;
  };

  const strategy = createDefaultStrategy();
  if (strategy) {
    window.onNuxtReady(() => {
      shareMutations({ predicate, strategy })(store);
    });
  }
};
