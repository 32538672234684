export const state = () => ({
  currentSearch: null,
  adminSearch: null,
  searchResults: [],
  backArrow: false,
  listFavorites: [],
  context: 'list',
  contextualPlaygrounds: [],
  contextContributions: null,
  contextComments: null,
  currentDate: null,
  keepAlive: true,
});

export const getters = {
  getCurrentSearch: (state) => state.currentSearch,
  hasCurrentSearch: (state) => !!state.currentSearch,
  getAdminSearch: (state) => state.adminSearch,
  getResults: (state) => state.searchResults,
  getBackArrow: (state) => state.backArrow,
  getListFavorites: (state) => state.listFavorites,
  getContext: (state) => state.context,
  getContextualPlaygrounds: (state) => state.contextualPlaygrounds,
  getContextContributions: (state) => state.contextContributions,
  getContextComments: (state) => state.contextComments,
  getCurrentDate: (state) => state.currentDate,
  getKeepAlive: (state) => state.keepAlive,
};

export const actions = {
  updateCurrentSearch({ commit }, payload) {
    commit('setCurrentSearch', payload);
  },
  updateAdminSearch({ commit }, payload) {
    commit('setAdminSearch', payload);
  },
  updateSearchResult({ commit }, payload) {
    commit('setSearchResult', payload);
  },
  updateListAverageGrade({ commit, state }, payload) {
    const { playId, averageGrade, numberGrade } = payload;
    const playgroundSearch = { ...state.searchResults.find((play) => play.id === playId) };

    if (playgroundSearch) {
      playgroundSearch.average_grade = averageGrade;
      playgroundSearch.number_grade = numberGrade ?? playgroundSearch.number_grade;
      const index = state.searchResults.findIndex((play) => play.id === playId);
      commit('updateOneSearchResult', { playground: playgroundSearch, index });
    }

    const playgroundContextual = { ...state.contextualPlaygrounds.find((play) => play.id === playId) };

    if (playgroundContextual) {
      playgroundContextual.average_grade = averageGrade;
      playgroundContextual.number_grade = numberGrade ?? playgroundContextual.number_grade;
      const index = state.contextualPlaygrounds.findIndex((play) => play.id === playId);
      commit('updateOneContextualPlaygrounds', { playground: playgroundContextual, index });
    }
  },
  updateBackArrow({ commit }, payload) {
    commit('setBackArrow', payload);
  },
  setListFavorites: ({ commit }, payload) => commit('updateListFavorites', payload),
  addToFavorites: ({ commit }, payload) => commit('addFavorite', payload),
  removeFromFavorites: ({ commit }, payload) => commit('removeFavorite', payload),
  resetListFavorites: ({ commit }) => commit('updateListFavorites', []),
  updateContext({ commit }, payload) {
    commit('setContext', payload);
  },
  updateContextualPlaygrounds({ commit }, payload) {
    commit('setContextualPlaygrounds', payload);
  },
  updateContextContributions({ commit }, payload) {
    commit('setContextContributions', payload);
  },
  updateContextComments({ commit }, payload) {
    commit('setContextComments', payload);
  },
  updateCurrentDate({ commit }, payload) {
    commit('setCurrentDate', payload);
  },
  resetContext({ commit }) {
    commit('setContext', 'list');
    commit('setContextualPlaygrounds', []);
    commit('setContextContributions', null);
    commit('setContextComments', null);
  },
  updateKeepAlive({ commit }, payload) {
    commit('setKeepAlive', payload);
  },
};

export const mutations = {
  setCurrentSearch: (state, payload) => (state.currentSearch = payload),
  setAdminSearch: (state, payload) => (state.adminSearch = payload),
  setSearchResult: (state, payload) => (state.searchResults = payload),
  setBackArrow: (state, payload) => (state.backArrow = payload),
  updateListFavorites: (state, payload) => (state.listFavorites = payload),
  updateOneSearchResult: (state, payload) => (state.searchResults.splice(payload.index, 1, payload.playground)),
  updateOneContextualPlaygrounds: (state, payload) => (state.contextualPlaygrounds.splice(payload.index, 1, payload.playground)),
  addFavorite: (state, payload) => (state.listFavorites.push(payload)),
  removeFavorite: (state, payload) => (state.listFavorites = [...state.listFavorites].filter((favorite) => favorite !== payload)),
  setContext: (state, payload) => (state.context = payload),
  setContextualPlaygrounds: (state, payload) => (state.contextualPlaygrounds = payload),
  setContextContributions: (state, payload) => (state.contextContributions = payload),
  setContextComments: (state, payload) => (state.contextComments = payload),
  setCurrentDate: (state, payload) => (state.currentDate = payload),
  setKeepAlive: (state, payload) => (state.keepAlive = payload),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
