export const state = () => ({
  geoToken: {
    access: null,
    exp: null,
  },
  mapToken: null,
  mapTokenExp: null,
});

export const getters = {
  getGeoToken: (state) => state.geoToken,
  getMapToken: (state) => state.mapToken,
  getMapTokenExp: (state) => state.mapTokenExp,
};

export const actions = {
  setGeoToken: ({ commit }, tokenData) => {
    tokenData.exp = Date.now() + tokenData.exp * 1000;
    commit('geoToken', tokenData);
  },
  resetGeoToken: ({ commit }) => {
    commit('geoToken', {
      access: null,
      exp: null,
    });
  },
  setMapToken: ({ commit }, tokenData) => {
    commit('mapToken', tokenData.token);
    commit('mapTokenExp', Date.now() + tokenData.exp * 1000);
  },
};

export const mutations = {
  geoToken: (state, payload) => (state.geoToken = payload),
  mapToken: (state, payload) => (state.mapToken = payload),
  mapTokenExp: (state, payload) => (state.mapTokenExp = payload),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
