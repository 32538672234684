import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

// eslint-disable-next-line import/prefer-default-export
export function createRouter() {
  const appRouter = new Router({
    mode: 'history',
    routes: [
      {
        path: '/404',
        name: '404',
        component: () => import(
          /* webpackChunkName: "404" */
          './layouts/error.vue'
        ).then((m) => m.default || m),
        meta: { topLevel: true },
        props: (route) => ({ url: route.params.url, error: { statusCode: 404 } }),
      },
      {
        path: '/',
        name: 'home',
        component: () => import(
          /* webpackChunkName: "search" */
          './pages/Search.vue'
        ).then((m) => m.default || m),
        meta: { topLevel: true },
      },
      /* webpackPrefetch: true */
      {
        path: '/offline',
        name: 'offline',
        component: () => import(
          /* webpackChunkName: "offline" */
          './pages/Offline.vue'
        ).then((m) => m.default || m),
        meta: { topLevel: true },
      },
      {
        path: '/ajout',
        name: 'addForm',
        component: () => import(
          /* webpackChunkName: "addForm" */
          './pages/AddForm.vue'
        ).then((m) => m.default || m),
        meta: { private: true, handleToolbar: true, parent: 'search' },
      },
      {
        path: '/avatar',
        name: 'Avatar',
        component: () => import(
          /* webpackChunkName: "Avatar" */
          './pages/Avatar.vue'
        ).then((m) => m.default || m),
        meta: { private: true, parent: 'Account' },
      },
      {
        path: '/compte',
        name: 'Account',
        component: () => import(
          /* webpackChunkName: "Account" */
          './pages/Account.vue'
        ).then((m) => m.default || m),
        meta: { private: true, topLevel: true },
      },
      {
        path: '/compte-perdu',
        name: 'AccountLost',
        component: () => import(
          /* webpackChunkName: "AccountLost" */
          './pages/AccountLost.vue'
        ).then((m) => m.default || m),
        meta: { parent: 'Login' },
      },
      {
        path: '/connexion',
        name: 'Login',
        component: () => import(
          /* webpackChunkName: "Login" */
          './pages/Login.vue'
        ).then((m) => m.default || m),
        meta: { partial: true, topLevel: true },
      },
      {
        path: '/contact',
        name: 'contact',
        component: () => import(
          /* webpackChunkName: "contact" */
          './pages/ContactForm.vue'
        ).then((m) => m.default || m),
        meta: { topLevel: true },
      },
      {
        path: '/contact/:token',
        name: 'ContactConfirm',
        props: (route) => {
          const token = route.params.token;
          return { token };
        },
        component: () => import(
          /* webpackChunkName: "ContactConfirm" */
          './pages/ContactConfirm.vue'
        ).then((m) => m.default || m),
        meta: { topLevel: true },
      },
      {
        path: '/departements',
        name: 'list-admin',
        component: () => import(
          /* webpackChunkName: "list-admin" */
          './pages/ListAdmin.vue'
        ).then((m) => m.default || m),
        meta: { parent: 'search' },
      },
      {
        path: '/inscription',
        name: 'Register',
        component: () => import(
          /* webpackChunkName: "Register" */
          './pages/Register.vue'
        ).then((m) => m.default || m),
        meta: { publicOnly: true, topLevel: true },
      },
      {
        path: '/maintenance',
        name: 'Maintenance',
        component: () => import(
          /* webpackChunkName: "Maintenance" */
          './pages/Maintenance.vue'
        ).then((m) => m.default || m),
        meta: { topLevel: true },
      },
      {
        path: '/mes-avis',
        name: 'PrivateComments',
        component: () => import(
          /* webpackChunkName: "PrivateComments" */
          './pages/PrivateComments.vue'
        ).then((m) => m.default || m),
        meta: { private: true, parent: 'Account' },
      },
      {
        path: '/mes-favoris',
        name: 'FavPlaygrounds',
        component: () => import(
          /* webpackChunkName: "FavPlaygrounds" */
          './pages/FavPlaygrounds.vue'
        ).then((m) => m.default || m),
        meta: { private: true, parent: 'Account' },
      },
      {
        path: '/mes-contributions',
        name: 'Contributions',
        component: () => import(
          /* webpackChunkName: "Contributions" */
          './pages/Contributions.vue'
        ).then((m) => m.default || m),
        meta: { private: true, parent: 'Account' },
      },
      {
        path: '/recherche',
        name: 'search',
        component: () => import(
          /* webpackChunkName: "search" */
          './pages/Search.vue'
        ).then((m) => m.default || m),
        meta: { topLevel: true },
      },
      {
        path: '/recovery/:token',
        name: 'Recovery',
        props: (route) => {
          const token = route.params.token;
          return { token };
        },
        component: () => import(
          /* webpackChunkName: "Recovery" */
          './pages/Recovery.vue'
        ).then((m) => m.default || m),
        meta: { partial: true, topLevel: true },
      },
      {
        path: '/unsubscribe/:token',
        name: 'Unsubscribe',
        props: true,
        component: () => import(
          /* webpackChunkName: "Unsubscribe" */
          './pages/Unsubscribe.vue'
        ).then((m) => m.default || m),
        meta: { topLevel: true },
      },
      {
        path: '/unsubscribe/:token/confirm',
        name: 'UnsubscribeConfirm',
        props: (route) => {
          const token = route.params.token;
          return { token, confirm: true };
        },
        component: () => import(
          /* webpackChunkName: "Unsubscribe" */
          './pages/Unsubscribe.vue'
        ).then((m) => m.default || m),
        meta: { topLevel: true },
      },
      {
        path: '/unsubscribe/:uuid/:token',
        redirect: (to) => ({ name: 'UnsubscribeConfirm', params: { token: to.params.token } }),
      },
      {
        path: '/unsubscribe/:uuid/:token/confirm',
        redirect: (to) => ({ name: 'UnsubscribeConfirm', params: { token: to.params.token } }),
      },
      {
        path: '/activate/:token',
        name: 'ActivateAccount',
        props: true,
        component: () => import(
          /* webpackChunkName: "ActivateAccount" */
          './pages/ActivateAccount.vue'
        ).then((m) => m.default || m),
        meta: { topLevel: true },
      },
      {
        path: '/check/:token',
        name: 'CheckEmail',
        props: (route) => {
          const token = route.params.token;
          return { token };
        },
        component: () => import(
          /* webpackChunkName: "CheckEmail" */
          './pages/CheckEmail.vue'
        ).then((m) => m.default || m),
        meta: { topLevel: true },
      },
      {
        path: '/confirm/:token',
        name: 'ConfirmEmail',
        props: (route) => {
          const token = route.params.token;
          return { token };
        },
        component: () => import(
          /* webpackChunkName: "ConfirmEmail" */
          './pages/ConfirmNewEmail.vue'
        ).then((m) => m.default || m),
        meta: { topLevel: true },
      },
      {
        path: '/re-bienvenue',
        name: 'WelcomeBack',
        component: () => import(
          /* webpackChunkName: "WelcomeBack" */
          './pages/WelcomeBack.vue'
        ).then((m) => m.default || m),
        meta: { private: true, parent: 'search' },
      },
      {
        path: '/cgu',
        name: 'CGU',
        component: () => import(
          /* webpackChunkName: "cgu" */
          './pages/Cgu.vue'
        ).then((m) => m.default || m),
        meta: { parent: 'search' },
      },
      // A garder à la fin
      {
        path: '/detail/:id',
        name: 'detail',
        props: (route) => {
          const id = parseInt(route.params.id, 10);
          if (Number.isNaN(id)) {
            return null;
          }
          return { id };
        },
        component: () => import(
          './pages/Detail.vue'
        ).then((m) => m.default || m),
        meta: { handleToolbar: true, parent: 'list-city' },
      },
      {
        path: '/departements/:admin',
        name: 'list-city-admin',
        props: (route) => {
          const admin = route.params.admin;
          return { admin };
        },
        component: () => import(
          /* webpackChunkName: "list-city-admin" */
          './pages/ListCityAdmin.vue'
        ).then((m) => m.default || m),
        meta: { parent: 'list-admin' },
      },
      {
        path: '/:admin/:place',
        name: 'list-city',
        props: (route) => {
          const place = route.params.place;
          const lat = Number(route.query.lat);
          const lon = Number(route.query.lon);
          // Geolocation of the user
          const userLocation = route.params.userLocation;
          if (Number.isNaN(lat) || Number.isNaN(lon)) {
            return { place, lat: null, lon: null };
          }
          return {
            place, lat, lon, userLocation,
          };
        },
        component: () => import(
          /* webpackChunkName: "list-city" */
          './pages/List.vue'
        ).then((m) => m.default || m),
        meta: { handleToolbar: true, parent: 'search' },
      },
      {
        path: '/:admin/:place/:id',
        name: 'detail-list',
        props: (route) => {
          const id = parseInt(route.params.id, 10);
          if (Number.isNaN(id)) {
            return null;
          }
          const listPlace = route.params.listPlace;
          const listLat = Number(route.params.listLat);
          const listLon = Number(route.params.listLon);
          const userLocation = route.params.userLocation;
          if (Number.isNaN(listLat) || Number.isNaN(listLon)) {
            return { id, listPlace };
          }
          return {
            id, listPlace, listLat, listLon, userLocation,
          };
        },
        component: () => import(
          /* webpackChunkName: "detail-list" */
          './pages/Detail.vue'
        ).then((m) => m.default || m),
        meta: { handleToolbar: true, parent: 'list-city' },
      },
      {
        path: '/:admin/:place/ajout-images/:id',
        name: 'DetailUpload',
        props: (route) => {
          let id = Number(route.params.id);
          if (Number.isNaN(id)) {
            id = null;
          }
          const playground = route.params.playground;
          const contribution = route.params.contribution;
          return { id, playground, contribution };
        },
        component: () => import(
          /* webpackChunkName: "DetailUpload" */
          './pages/DetailUpload.vue'
        ).then((m) => m.default || m),
        meta: { private: true, parent: 'detail-list' },
      },
      {
        path: '/:admin/:place/avis/:id',
        name: 'comments',
        props: (route) => {
          let id = Number(route.params.id);
          if (Number.isNaN(id)) {
            id = null;
          }
          return { id };
        },
        component: () => import(
          /* webpackChunkName: "comments" */
          './pages/Comments.vue'
        ).then((m) => m.default || m),
        meta: { parent: 'detail-list' },
      },
      {
        path: '/:admin/:place/galerie/:id',
        name: 'gallery',
        props: (route) => {
          let id = Number(route.params.id);
          if (Number.isNaN(id)) {
            id = null;
          }
          const detailImages = route.params.detailImages;
          return {
            id, detailImages,
          };
        },
        component: () => import(
          /* webpackChunkName: "gallery" */
          './pages/gallery.vue'
        ).then((m) => m.default || m),
        meta: { parent: 'detail-list' },
      },
      // {
      //   path: '*',
      //   redirect: '/404',
      //   meta: { topLevel: true },
      // },
    ],
  });
  return appRouter;
}
