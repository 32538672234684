import errorLang from '../libraries/lang/errors';

export default {
  data() {
    return {
      errors$: {},
      apiErrors$: {},
      asyncErrors$: [],
      defaultErrors$: [],
      firstError$: null,
      timeoutId$: null,
    };
  },
  created() {
    const preErrors = {};

    const getPreErrors = (env) => {
      if (!preErrors[env]) {
        preErrors[env] = errorLang.getEnvErrors(env, this.extra);
      }
    };

    const getApiPreErrorMessage = (env, field, errorName = 'default') => {
      const defaultParamMessage = 'Paramètre incorrect';
      const defaultFormMessage = 'Une erreur est survenue';

      if (!field) return defaultParamMessage;

      getPreErrors(env);
      if (preErrors[env] && preErrors[env][field]) {
        return preErrors[env][field][errorName] || (field === 'form' ? defaultFormMessage : defaultParamMessage);
      }
      return field === 'form' ? defaultFormMessage : defaultParamMessage;
    };

    // --------------------------------------------------------------------------------------------------------------------

    this.errors$.get = (env, field) => {
      if (this.apiErrors$[env] && this.apiErrors$[env][field]) {
        return this.apiErrors$[env][field].message;
      }
      return null;
    };

    this.errors$.getCode = (env, field) => {
      if (this.apiErrors$[env] && this.apiErrors$[env][field]) {
        return this.apiErrors$[env][field].code;
      }
      return null;
    };

    this.errors$.addError = (errors) => {
      if (!errors.url || (errors.url && (errors.url === this.url))) {
        this.apiErrors$[errors.env] = {};
        const buildErrors = errorLang.getErrors(errors.env, errors.fields, errors.extra);
        if (this.fieldsRefs) {
          this.fieldsRefs.forEach((fieldRef) => {
            const [env, field] = fieldRef.split('-');
            if (env === errors.env && buildErrors[field] && !this.firstError$) {
              this.firstError$ = `${errors.env}-${field}`;
            }
          });
        }
        Object.keys(buildErrors).forEach((key) => {
          this.apiErrors$[errors.env][key] = { code: buildErrors[key].code, message: buildErrors[key].message, extra: errors.extra };
        });
        this.apiErrors$ = JSON.parse(JSON.stringify(this.apiErrors$));
      }
    };

    this.errors$.getAlls = () => this.apiErrors$;

    this.errors$.getAll = (env) => this.apiErrors$[env];

    this.errors$.focusFirstError = async () => {
      await this.$nextTick();
      if (this.firstError$ && this.$refs[this.firstError$]) {
        // The object must have the "hasFocused" property and must be an html element or have a "focus" function
        if (
          (this.$refs[this.firstError$] instanceof HTMLElement || Object.prototype.hasOwnProperty.call(this.$refs[this.firstError$], 'hasFocused'))
          && typeof this.$refs[this.firstError$].focus === 'function'
        ) {
          this.$refs[this.firstError$].focus();
        } else if (this.$refs[this.firstError$].$el instanceof HTMLElement && typeof this.$refs[this.firstError$].$el.focus === 'function') {
          // object.$el must be an html element
          this.$refs[this.firstError$].$el.focus();
          // If object.$el does not have focus, and the parent element is an html element
          // we try to focus on the parent element
          if (
            this.$refs[this.firstError$].$el !== document.activeElement
            && this.$refs[this.firstError$].$el.parentElement instanceof HTMLElement
            && typeof this.$refs[this.firstError$].$el.parentElement.focus === 'function'
          ) {
            // Caution: for this to work, the parent element must have a "tabindex" attribute
            this.$refs[this.firstError$].$el.parentElement.focus();
          }
        }
      }
    };

    this.errors$.mergeErrors = (errors) => {
      Object.keys(errors).forEach((env) => {
        if (!this.apiErrors$[env]) this.apiErrors$[env] = {};
        Object.keys(errors[env]).forEach((field) => {
          this.apiErrors$[env][field] = errors[env][field];
          if (!this.apiErrors$[env][field]) this.$delete(this.apiErrors$[env], field);
        });
        if (Object.keys(this.apiErrors$[env]).length === 0) this.$delete(this.apiErrors$, env);
      });
      this.apiErrors$ = JSON.parse(JSON.stringify(this.apiErrors$));
    };

    this.errors$.checkError = (condition, env, field, delay = 250, errorName = 'default') => {
      clearTimeout(this.timeoutId$);
      if (!condition) {
        this.errors$.handleError(condition, env, field, errorName);
      } else {
        this.timeoutId$ = setTimeout(() => {
          this.errors$.handleError(condition, env, field, errorName);
        }, delay);
      }
    };

    this.errors$.hasErrors = (env = null) => {
      const allEnv = Object.keys(this.apiErrors$);
      if (env) {
        return allEnv.includes(env);
      }
      let bool = false;
      allEnv.forEach((envi) => {
        if (Object.keys(envi).length > 0) bool = true;
      });
      return bool;
    };

    this.errors$.handleError = (condition, env, field, errorName = 'default') => {
      if (condition) {
        if (!this.firstError$) {
          this.firstError$ = `${env}-${field}`;
        }
        this.apiErrors$[env] = { ...this.apiErrors$[env], ...{ [field]: { code: errorName, message: getApiPreErrorMessage(env, field, errorName) } } };
      } else if (this.apiErrors$[env] && this.apiErrors$[env][field]) {
        if (this.firstError$ === `${env}-${field}`) this.firstError$ = null;

        this.$delete(this.apiErrors$[env], field);
        if (Object.keys(this.apiErrors$[env]).length === 0) {
          this.$delete(this.apiErrors$, env);
        }
      }
      this.apiErrors$ = JSON.parse(JSON.stringify(this.apiErrors$));
    };

    this.errors$.handleErrors = (errors, env) => {
      errors.forEach((error) => {
        this.errors$.handleError(error.condition, env, error.field, error.code || 'default');
      });
    };

    this.errors$.reset = (env = false) => {
      if (env) {
        delete this.apiErrors$[env];
      } else {
        this.apiErrors$ = {};
      }
      this.firstError$ = null;
    };

    this.errors$.ready = true;
  },
  mounted() {
    this.asyncErrors$.forEach((error) => {
      if (error && error.env) {
        const data = {
          env: error.env,
          fields: error.errors,
          extra: error.extra,
          url: error.url,
        };
        this.errors$.addError(data);
      }
    });

    this.defaultErrors$.forEach((error) => {
      if (!this.errors$.hasErrors(error.env)) {
        this.errors$.handleError(true, error.env, error.field, error.errorName);
      }
    });
  },
};
