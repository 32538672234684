/* eslint-disable no-use-before-define */ // Circular call
/* eslint-disable-next-line no-unused-vars */ // Used in JSDoc
import axios from 'axios';

let myStore = null;
let myRouter = null;
let myBugsnag = null;
let myFirebase = null;

let headerLoading = false;
let loginLoading = false;
let geoLoading = false;
let publicQueue = [];
let privateQueue = [];
let geoQueue = [];

export default ({
  app, store, $bugsnag,
}) => {
  myStore = store;
  myRouter = app.router;
  myBugsnag = $bugsnag;

  getStore().dispatch('authToken/resetAuth');
  getStore().dispatch('map/resetGeoToken');
};

export function getStore() {
  return myStore;
}

export function getRouter() {
  return myRouter;
}

export function getBugsnag() {
  return myBugsnag;
}

function getFirebase() {
  return myFirebase;
}

export function setFirebase(firebase) {
  myFirebase = firebase;
}

export function launchPublicQueue() {
  publicQueue.forEach((req) => {
    req.promise().then((response) => {
      req.resolve(response);
    }).catch((error) => {
      req.reject(error);
    });
  });
  publicQueue = [];
}

export function clearPublicQueue() {
  publicQueue = [];
}

export function addPublicQueue(data) {
  publicQueue.push(data);
}

export function launchPrivateQueue() {
  privateQueue.forEach((req) => {
    req.promise().then((response) => {
      req.resolve(response);
    }).catch((error) => {
      req.reject(error);
    });
  });
  privateQueue = [];
}

export function addPrivateQueue(data) {
  privateQueue.push(data);
}

export function clearPrivateQueue() {
  privateQueue = [];
}

export function launchGeoQueue() {
  geoQueue.forEach((req) => {
    req.promise().then((response) => {
      req.resolve(response);
    }).catch((error) => {
      req.reject(error);
    });
  });
  geoQueue = [];
}

export function clearGeoQueue() {
  geoQueue = [];
}

export function addGeoQueue(data) {
  geoQueue.push(data);
}

/**
 * Construit les Headers avec Authorization Bearer
 * @param {any} headers Headers additionnels à ajouter à la requête
 * @returns {any} Headers avec Authorization
 */
export function buildHeaders(access, headers = {}) {
  return {
    ...headers,
    Authorization: `Bearer ${access}`,
  };
}

export async function jwtHeaders(refresh, refreshPublic, isPublic) {
  if (headerLoading) {
    return false;
  }
  headerLoading = true;
  await getStore().dispatch('authToken/resetToken');

  return axios.get(`${process.env.VUE_APP_API_URL}${refresh ? 'refresh' : 'auth'}`, { withCredentials: true })
    .then(async (tokenData) => {
      await getStore().dispatch('authToken/setToken', {
        access: tokenData.data.access_token,
        exp: tokenData.data.expires_in,
        isPublic: !refresh || refreshPublic,
      });
      headerLoading = false;
      launchPublicQueue();

      if (!refreshPublic) {
        if (refresh) {
          launchPrivateQueue();
        } else if (getStore().getters['firebase/access']) {
          const loginCall = loginHeader(tokenData.data.access_token);
          if (!isPublic) {
            return loginCall;
          }
        } else if (!isPublic) {
          return null;
        }
      }

      return tokenData.data.access_token;
    })
    .catch(async (errToken) => {
      if (errToken
        && errToken.response
        && (errToken.response.status === 401 || errToken.response.status === 400)) {
        await getStore().dispatch('authToken/resetAuth');
        headerLoading = false;
        return jwtHeaders(false, refreshPublic, isPublic);
      }
      headerLoading = false;
      clearPublicQueue();
      clearPrivateQueue();
      clearGeoQueue();
      return null;
    });
}

export function loginHeader(token) {
  if (loginLoading) {
    return false;
  }
  loginLoading = true;

  const isAuth = getStore().getters['authToken/isAuth'];
  const userSecured = getStore().getters['preferences/userSecured'];
  const emailToken = getStore().getters['authToken/emailToken'];

  /**
   * We need a token for private scope.
   * We take the mail token if user is not secured (for partial auth)
   * or the secured token from firebase if the user is secured
   */
  const data = isAuth && !userSecured && emailToken
    ? { type: 'email', token: emailToken }
    : { type: 'firebase', token: getStore().getters['firebase/access'] };

  const request = {
    method: 'POST',
    url: handleUrlPath('login'),
    data,
    headers: buildHeaders(token),
    withCredentials: true,
  };

  return axios.request(request)
    .then(async (tokenData) => {
      await getStore().dispatch('authToken/setToken', {
        access: tokenData.data.access_token,
        exp: tokenData.data.expires_in,
        isPublic: false,
      });
      loginLoading = false;
      launchPrivateQueue();
      return tokenData.data.access_token;
    })
    .catch((errToken) => {
      if (errToken
        && errToken.response
        && errToken.response.status === 401) {
        getStore().dispatch('preferences/setGlobalError', {
          env: 'http',
          fields: { 401: 'default' },
          extra: [],
          url: null,
        });
      }

      if (userSecured) {
        firebaseLogout();
      } else {
        getStore().dispatch('authToken/setIsAuth', false);
      }

      clearPrivateQueue();
      loginLoading = false;
      return null;
    });
}

export async function geoHeader(token) {
  if (geoLoading) {
    return false;
  }
  geoLoading = true;
  await getStore().dispatch('map/resetGeoToken');

  const request = {
    method: 'GET',
    url: handleUrlPath('geo/token'),
    headers: buildHeaders(token),
  };
  return axios.request(request)
    .then((tokenData) => {
      getStore().dispatch('map/setGeoToken', {
        access: tokenData.data.access_token,
        exp: tokenData.data.expires_in,
      });
      geoLoading = false;
      launchGeoQueue();
      return tokenData.data.access_token;
    })
    .catch((errToken) => {
      if (errToken
        && errToken.response
        && errToken.response.status === 401) {
        getStore().dispatch('authToken/resetToken');
      }
      clearGeoQueue();
      geoLoading = false;
      return null;
    });
}

/**
 * Gère URL relative/absolue
 * @param {string} url absolue ou relative
 * @returns {string} url absolue
 */
export function handleUrlPath(url, scope = 'public') {
  // URL qui débute avec protocole suivie de ://
  if (/^(?:[a-z]+:)?\/\//i.test(url)) {
    return url;
  }

  // Supprime le premier '/' si présent
  url = url[0] === '/' ? url.substring(1) : url;

  return (scope === 'geonames' ? process.env.VUE_APP_API_GEO_URL : process.env.VUE_APP_API_URL) + url;
}

/**
 * Emit errors to be handled by error-handler mixin
 * @param {Object} context Instance du composant ayant appelé l'api
 * @param {any} error error from API
 */
export function commitError(context, error) {
  if (!!context && !!error?.response?.data?.env) {
    const data = {
      env: error.response.data.env,
      fields: error.response.data.errors,
      extra: error.response.data.extra,
      url: error?.response?.data?.url ?? null,
    };
    context.errors$.addError(data);
  }
}

export function firebaseLogout() {
  const userSecured = getStore().getters['preferences/userSecured'];

  if (userSecured) {
    getFirebase().signOut();

    getStore().dispatch('preferences/setGlobalError', {
      env: 'http',
      fields: { 401: 'logout' },
      extra: [],
      url: null,
    });
  }
}
