import * as apiGeonames from '../api/geonames/geo';

import Location from '../models/location';
import { getBugsnag } from '../api/helpers';

const config = {
  maximumAge: 5 * 60 * 1000, // Temps maximum du cache de la position (ms)
  timeout: 10 * 1000, // Timeout de la recherche de position (ms)
  enableHighAccuracy: true, // Mode haute précision (gourmand en ressources)
};

// eslint-disable-next-line import/prefer-default-export
export function geolocation(enableHighAccuracy, context) {
  config.enableHighAccuracy = enableHighAccuracy;

  return new Promise((resolve, reject) => {
    // Code 2 : POSITION_UNAVAILABLE
    const defaultError = { code: 2, message: 'An error has occurred' };

    function errorCallback(positionError) {
      let code = '';

      // Other possible error codes : POSITION_UNAVAILABLE, TIMEOUT
      switch (positionError.code) {
        case positionError.PERMISSION_DENIED:
          code = 'permission_denied';
          break;
        default:
          code = 'default';
          break;
      }

      context.errors$.addError({
        env: 'geo',
        fields: { position: code },
        extra: [],
        url: null,
      });

      reject(positionError);
    }

    function successCallback(position) {
      try {
        // Prevalidation
        if (position.coords.latitude < -90 || position.coords.latitude > 90 || position.coords.latitude === 0) {
          errorCallback({ ...defaultError, message: 'Wrong latitude' });
          return;
        }

        if (position.coords.longitude < -180 || position.coords.longitude > 180 || position.coords.longitude === 0) {
          errorCallback({ ...defaultError, message: 'Wrong longitude' });
          return;
        }

        // Appel à l'api de reverse pour obtenir une localité
        apiGeonames.reverse({ lat: position.coords.latitude, lon: position.coords.longitude }, null).then((res) => {
          // Search by geographical coordinates
          res.result.geoSearch = true;
          // Geolocation of the user
          res.result.userLocation = true;
          const location = new Location(res.result);

          // Les coordonnées exacts de l'utilisateur sont conservées
          location.location.lat = position.coords.latitude;
          location.location.lon = position.coords.longitude;
          resolve(location);
        }).catch((error) => {
          if (error.response?.status === 400 || error.response?.status === 404) {
            context.errors$.addError({
              env: 'geo',
              fields: { position: 'default' },
              extra: [],
              url: null,
            });
          }
          reject(error);
        });
      } catch (e) {
        getBugsnag().notify(e);
        errorCallback(defaultError);
      }
    }

    if (!navigator.geolocation) {
      errorCallback(defaultError);
      return;
    }

    setTimeout(() => {
      navigator.geolocation.getCurrentPosition(successCallback, errorCallback, config);
    }, 250);
  });
}
