// Routing custom (http://localhost:8012/doc/front/plugins/routerCustom)

import {
  initHelpers, routingValidation, handleOffline, notSameRoute, getToUrl,
} from '../libraries/routingHelpers';

export default ({ store, isDev, $bugsnag }, inject) => {
  const stateCustom = (data, title, url, method) => {
    if (method === 'pushState') {
      store.dispatch('router/addPosition');
    }
    window.history[method](data, title, url);
  };

  const routerCustom = async (url, method) => {
    if (!store.getters['router/getRouteLoading']) {
      store.dispatch('router/setRouteLoading', true);
      const { $route, $router, isOffline } = window.$nuxt;
      initHelpers({
        from: $route,
        to: url,
        store,
        router: $router,
      }, true);

      routingValidation();
      if (isOffline && !isDev) {
        await handleOffline();
      }
      if (notSameRoute()) {
        if (method === 'push') {
          store.dispatch('router/addPosition');
          store.dispatch('router/addHistory', $route);
        }

        await $router[method](getToUrl())
          .then(() => {}).catch((e) => {
            $bugsnag.notify(e);
            store.dispatch('router/setRouteLoading', false);
          });
      } else {
        store.dispatch('router/setRouteLoading', false);
      }
    }
  };

  const back = () => {
    if (!store.getters['router/getRouteLoading']) {
      store.dispatch('router/setRouteLoading', true);

      const { $route } = window.$nuxt;
      const history = store.getters['router/getHistory'];
      const isDetailList = $route.name === 'detail-list' || $route.name === 'detail';
      const isParentDetailList = $route.parent === 'detail-list';
      const getContext = store.getters['transferData/getContext'];
      const displayLarge = store.getters['screen/isMedium'] && store.getters['screen/isLandscape'];
      const isDetailDesktop = isDetailList && getContext === 'list' && displayLarge;
      const isDetailMobile = isDetailList && getContext === 'list' && !displayLarge;
      const getDetailFromMap = store.getters['preferences/getDetailFromMap'];
      const isPwa = store.getters['preferences/isPwa'];

      // Back from the detail, in desktop and not in map view
      if (history.length > 1 && isDetailDesktop && !getDetailFromMap && history[history.length - 1].name === 'list-city' && (history[history.length - 2].name === 'search' || history[history.length - 2].name === 'home')) {
        store.dispatch('router/removeLastHistory');
        window.$nuxt.$router.replace({ name: 'search' });
        return;
      }

      if (history.length === 1 && isPwa && isDetailMobile) {
        // Replace by parent
        store.dispatch('router/removeLastHistory');
        window.$nuxt.$router.replace({
          name: $route.meta.parent,
          params: isParentDetailList ? { ...$route.params } : {},
        });
        return;
      }

      if (history.length > 0) {
        // Go back
        store.dispatch('router/removeLastHistory');
        store.dispatch('router/removePosition');
        window.$nuxt.$router.back();
        return;
      }

      if ($route.meta.parent) {
        // Back from detail
        if (isDetailDesktop) {
          store.dispatch('router/removeLastHistory');
          window.$nuxt.$router.replace({ name: 'search' });
          return;
        }

        // Push to parent
        store.dispatch('router/addPosition');
        window.$nuxt.$router.push({
          name: $route.meta.parent,
          params: isParentDetailList ? { ...$route.params } : {},
        });
        return;
      }

      // Push to default page: 'search'
      store.dispatch('router/addPosition');
      window.$nuxt.$router.push({ name: 'search' });
    }
  };

  const cancel = () => {
    store.dispatch('router/setRouteLoading', false);
  };

  // Inject in $root & context (https://nuxtjs.org/docs/directory-structure/plugins/#inject-in-root--context)
  inject('newRouter', {
    back: () => back(),
    push: (url) => routerCustom(url, 'push'),
    replace: (url) => routerCustom(url, 'replace'),
    pushState: (data, title, url) => stateCustom(data, title, url, 'pushState'),
    replaceState: (data, title, url) => stateCustom(data, title, url, 'replaceState'),
    cancel: () => cancel(),
    // Ne pas utilisé car ne gère pas la nav PWA
    go: (index) => window.$nuxt.$router.go(index),
  });
};
