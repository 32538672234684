import minifyTheme from 'minify-css-string';

export default {
  ltr: true,
  icons: {
    iconfont: 'md',
  },
  theme: {
    dark: false,
    options: {
      minifyTheme,
      customProperties: true,
      variations: false,
    },
    themes: {
      light: {
        'actif-lighten1': '#44d8f0',
        'actif-lighten3': '#8affff', // bleu clair
        'actif-lighten4': '#aaffff', // bleu+clair
        'actif-lighten5': '#c9ffff', // bleu++clair
        primary: '#1e0037',
        secondary: '#4a4f53',
        accent: '#464646',
        actif: '#00bcd4',
        'primary-lighten2': '#8733c7', // violet
        primaryB: '#673ab7',
        'primaryB-lighten1': '#8353d3',
        'primaryB-lighten2': '#a06cf0',
        'primaryB-lighten5': '#f8bdff',
        'dark-lighten1': '#1a2128',
        'darkgrey-darken1': '#1b1b1b',
        'green2-lighten2': '#d4ff6c', // jaune-vert-clair
        'info-lighten3': '#95e8ff',
        'info-lighten5': '#d4ffff',
        'purpleAccent-darken1': '#4b1a7f', // violet fonçé
        'success-darken2': '#00791e',
        'success-lighten1': '#69cb69',
        'success-lighten2': '#85e783',
        'success-lighten4': '#beffba',
        'success-lighten5': '#dcffd6',
        borderColor: '#e0e0e0',
        dark: '#000a14',
        darkgrey: '#303030',
        dede: '#dedede',
        error: '#d4083d',
        fafa: '#fafafa',
        green: '#83a428',
        green2: '#9acd32',
        // green3: '#94d669',
        // green3: '#FF0066',
        info: '#2196f3',
        purpleAccent: '#663399',
        semi: '#232636',
        success: '#4caf50',
        warning: '#ffc107',
        white: '#ffffff',
        'white-darken1': '#e2e2e2',
      },
      dark: {
        'actif-lighten1': '#b1db5b', // jaune/vert
        'actif-lighten3': '#ebff91', // jaune/vcert clair
        'actif-lighten4': '#ffffad', // jaune/vert+clair
        'actif-lighten5': '#ffffc9', // jaune/vert++clair
        primary: '#95bf40', /* vert desaturé */
        secondary: '#4a4f53', /* gris bleu */
        accent: '#dedede',
        actif: '#95bf40',
        'primary-lighten2': '#cef876',
        primaryB: '#aee66b',
        'primaryB-lighten1': '#cbff86',
        'primaryB-lighten2': '#e8ffa1',
        'primaryB-lighten5': '#fffff7',
        green: '#7e9933',
        'green2-lighten2': '#cef876',
        // green3: '#97d071',
        purpleAccent: '#663399',
        'purpleAccent-darken1': '#4b1a7f', // violet-foncé
        'success-darken2': '#00791e',
        'success-lighten1': '#69cb69',
        'success-lighten2': '#85e783',
        'success-lighten4': '#beffba',
        'success-lighten5': '#dcffd6',
        'white-darken1': '#e2e2e2',
        borderColor: '#3e404e',
        dark: '#262525',
        'dark-lighten1': '#3c3b3b',
        darkgrey: '#1a1c29',
        'darkgrey-darken1': '#000115',
        dede: '#dedede',
        error: '#fb0f2f',
        fafa: '#fafafa',
        info: '#2196f3',
        'info-lighten3': '#95e8ff',
        'info-lighten5': '#d4ffff',
        semi: '#232636', // fond pour les v-sheets et vcard
        semiLighter: '#34384e',
        success: '#4caf50',
        warning: '#ffc107',
        white: '#ffffff',
      },
    },
  },
};
