// Router Middleware (https://nuxtjs.org/docs/directory-structure/middleware/#router-middleware)

import {
  initHelpers, noDialog, routingValidation, notSameRoute, getToUrl, convertOldAdj,
} from '../libraries/routingHelpers';

export default async ({
  from, route, store, app, redirect,
}) => {
  if (route.meta[0]?.topLevel) {
    store.dispatch('router/resetHistory');
  }

  if (!route.params?.appNav) {
    initHelpers({
      from,
      to: route,
      store,
      router: app.router,
    });
    if (process.server || (process.client && await noDialog())) {
      // Unknown route
      if (!route.name) {
        await convertOldAdj();
      }
      routingValidation();
      if (notSameRoute()) {
        // if redirection
        if (getToUrl().replace) {
          redirect(getToUrl());
        }

        return;
      }
    }
    // cancel navigation
    redirect(from);
  }
};
