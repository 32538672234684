import {
  get, post, put, patch, del,
} from './http';

export function login(data, context) {
  return post(context, 'login', data, true);
}

export function logout(context) {
  return del(context, 'logout', {}, true);
}

export function register(data, context) {
  return post(context, 'register', data);
}

export function recovery(data, context) {
  return post(context, 'account/recovery', data);
}

export function validateRecoveryToken(uuid, context) {
  return get(context, `account/validate/recovery/${uuid}`);
}

export function completeRecovery(uuid, data, context) {
  return put(context, `account/recovery/${uuid}`, data);
}

export function restoration(access, context) {
  return patch(context, 'account/restoration', { access_token: access });
}

export function fcmToken(token, context) {
  return post(context, 'token/push', { token });
}
