import {
  get,
  patch,
  put,
  del,
} from './http';

export function changePassword(data, context) {
  return put(context, 'profile/password', data);
}

export function changeEmail(data, context) {
  return put(context, 'profile/email', data);
}

export function changeName(data, context) {
  return put(context, 'profile/name', data);
}

export function preferences(context) {
  return get(context, 'profile/preference');
}

export function updatePreference(data, context) {
  return patch(context, 'profile/preference', data);
}

export function deleteProfile(context) {
  return del(context, 'profile');
}
