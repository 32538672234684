import {
  options as optionsWrapper,
  get as getWrapper,
  post as postWrapper,
  patch as patchWrapper,
  put as putWrapper,
  del as delWrapper,
  getHeaders as getHeadersWrapper,
  handleErrors as handleErrorsWrapper,
  scopePrivate,
} from '../wrapper';

export const options = (context, url, data = {}, headers = {}) => optionsWrapper(context, scopePrivate, url, data, headers);

export const get = (context, url, params = {}, headers = {}, cancelable = false) => getWrapper(context, scopePrivate, url, params, headers, cancelable);

export const post = (context, url, data = {}, withCredentials = false, headers = {}) => postWrapper(context, scopePrivate, url, data, withCredentials, headers);

export const put = (context, url, data = {}, headers = {}) => putWrapper(context, scopePrivate, url, data, headers);

export const patch = (context, url, data = {}, headers = {}) => patchWrapper(context, scopePrivate, url, data, headers);

export const del = (context, url, params = {}, withCredentials = false, headers = {}) => delWrapper(context, scopePrivate, url, params, withCredentials, headers);

export const getHeaders = (headers = {}) => getHeadersWrapper(scopePrivate, headers);

export const handleErrors = (context, error, retry = true, request, nbTried = 0) => handleErrorsWrapper(context, scopePrivate, error, retry, request, nbTried);
