export const state = () => ({
  userControl: {
    active: false,
    control: null,
    loadingConfirm: false,
    loadingRefuse: false,
  },
  dialogRestoreAccount: {
    open: false,
    loading: false,
  },

});

export const getters = {
  getUserControl: (state) => state.userControl,
  dialogRestoreAccount: (state) => state.dialogRestoreAccount,
};

export const actions = {
  resetUserControl({ commit }) {
    commit('userControl', {
      active: false,
      control: null,
      loadingConfirm: false,
      loadingRefuse: false,

    });
  },
  setUserControlActive({ commit, state }, payload) {
    commit('userControl', {
      active: payload,
      control: payload ? state.userControl.control : false,
      loadingConfirm: state.userControl.loadingConfirm,
      loadingRefuse: state.userControl.loadingRefuse,

    });
  },
  setUserControl({ commit, state }, payload) {
    commit('userControl', {
      active: state.userControl.active,
      control: payload,
      loadingConfirm: state.userControl.loadingConfirm,
      loadingRefuse: state.userControl.loadingRefuse,

    });
  },
  setUserControlLoadingConfirm({ commit, state }, payload) {
    commit('userControl', {
      active: state.userControl.active,
      control: state.userControl.control,
      loadingConfirm: payload,
      loadingRefuse: state.userControl.loadingRefuse,
    });
  },
  setUserControlLoadingRefuse({ commit, state }, payload) {
    commit('userControl', {
      active: state.userControl.active,
      control: state.userControl.control,
      loadingConfirm: state.userControl.loadingConfirm,
      loadingRefuse: payload,
    });
  },
  setDialogRestoreAccountOpen({ commit, state }, payload) {
    commit('dialogRestoreAccount', {
      open: payload,
      loading: state.dialogRestoreAccount.loading,
    });
  },
  setDialogRestoreAccountLoading({ commit, state }, payload) {
    commit('dialogRestoreAccount', {
      open: state.dialogRestoreAccount.open,
      loading: payload,
    });
  },

};

export const mutations = {
  userControl: (state, payload) => (state.userControl = payload),
  dialogRestoreAccount: (state, payload) => (state.dialogRestoreAccount = payload),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
