import { setCookie } from '../libraries/cookies';

export const state = () => ({
  isAuth: null,
  token: {
    access: null,
    exp: 0,
    isPublic: true,
  },
  emailToken: null,
  awaitAuth: false,
});

export const getters = {
  isAuth: (state) => state.isAuth,
  token: (state) => state.token,
  emailToken: (state) => state.emailToken,
  awaitAuth: (state) => state.awaitAuth,
};

export const actions = {
  setIsAuth({ commit }, payload) {
    commit('auth', payload);
  },
  setToken: ({ commit }, tokenData) => {
    commit('token', {
      access: tokenData.access,
      exp: Date.now() + (tokenData.exp * 1000),
      isPublic: tokenData.isPublic,
    });
  },
  resetToken: ({ commit, state }) => {
    commit('token', {
      access: null,
      exp: 0,
      isPublic: state.token.isPublic,
    });
  },
  resetAuth: ({ commit }) => {
    commit('token', {
      access: null,
      exp: 0,
      isPublic: true,
    });
  },
  setEmailToken({ commit }, payload) {
    commit('updateEmailToken', payload);
    if (process.client) {
      if (payload) {
        setCookie('emailToken', payload, 30, process.env.VUE_APP_HTTPS === 1);
      } else {
        setCookie('emailToken', 'reset', -1, false);
      }
    }
  },
  setAwaitAuth({ commit }, payload) {
    commit('await', payload);
  },
};

export const mutations = {
  auth: (state, payload) => (state.isAuth = payload),
  token: (state, payload) => (state.token = payload),
  updateEmailToken: (state, payload) => (state.emailToken = payload),
  await: (state, payload) => (state.awaitAuth = payload),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
