const citiesWithBoroughs = ['Lyon-69', 'Marseille-13', 'Paris-75'];
const regex = /^(.+)@(.+)\.(.+)$/;

export function splitAndRessort(source, complex) {
  const sections = [];

  if (!complex) {
    complex = 2;
  }

  for (let i = 0; i < complex; i += 1) {
    sections[i] = '';
  }

  for (let i = 0; i < source.length; i += 1) {
    const section = i % complex;
    sections[section] += source.charAt(i);
  }

  return sections.reverse().join('');
}

export function isOdd(num) {
  return !(num % 2);
}

export function isEven(num) {
  return !!(num % 2);
}

export function getImageSize(width, sizes) {
  if (width <= sizes.xs) {
    return 'xs';
  }
  if (width <= sizes.sm) {
    return 'sm';
  }
  if (width <= sizes.md) {
    return 'md';
  }
  return 'lg';
}

export function getDisplayPlaceName(city, sub) {
  if (citiesWithBoroughs.find((e) => e === city.alias) && sub) {
    return sub.name;
  }
  return city.name;
}

export function getShapeId(city, sub) {
  if (citiesWithBoroughs.find((e) => e === city.alias) && sub) {
    return sub.id;
  }
  return city.id;
}

export function getPlaceNameWithPreposition(placeName) {
  if (placeName.indexOf('Le ') === 0) {
    return `au ${placeName.substr(3).trim()}`;
  }
  if (placeName.indexOf('Les ') === 0) {
    return `aux ${placeName.substr(4).trim()}`;
  }
  return `à ${placeName}`;
}

// Public method : check if email is well formatted
export function emailIsWellFormatted(email) {
  return regex.test(email);
}

export function capitalizeFirstLetter([first, ...rest], locale = navigator.language) {
  return first.toLocaleUpperCase(locale) + rest.join('');
}

export function uncapitalizeFirstLetter([first, ...rest], locale = navigator.language) {
  return first.toLocaleLowerCase(locale) + rest.join('');
}
