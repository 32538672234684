import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, isSupported } from 'firebase/messaging';
import {
  getAuth,
  signOut,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signInWithPopup,
  onAuthStateChanged,
  onIdTokenChanged,
  getIdToken,
} from 'firebase/auth';

import * as authApi from '../api/public/authentication';
import { setFirebase } from '../api/helpers';

export default ({ store, $bugsnag }, inject) => {
  const config = {
    apiKey: 'AIzaSyB8hhxZppMQgxCXk8ZwfhahlRD_wvktZR8',
    authDomain: 'aire-de-jeux-91995.firebaseapp.com',
    projectId: 'aire-de-jeux-91995',
    storageBucket: 'aire-de-jeux-91995.appspot.com',
    messagingSenderId: '332950584134',
    appId: '1:332950584134:web:235769833117eea7cbc347',
  };

  const firebaseApp = initializeApp(config);
  const auth = getAuth(firebaseApp);

  const tokensSent = [];

  const sendToken = (token, user = null) => {
    const tokenAlreadySent = tokensSent.find((t) => t.token === token);
    // if already sent with an user, don't resend it if user is now disconnected
    if (tokenAlreadySent && tokenAlreadySent.user && !user) return;

    // If previously sent with not user, and user is now set, update it
    if (user && tokenAlreadySent && !tokenAlreadySent.user) tokenAlreadySent.user = user;

    authApi.fcmToken(token, null).catch((error) => {
      let data = null;
      if (error?.response?.data?.env) {
        data = {
          env: error.response.data.env,
          fields: error.response.data.errors,
          extra: error.response.data.extra,
          url: error.response.data.url,
        };
      } else if (error.response.status <= 500) {
        data = {
          env: 'token',
          fields: { token: 'default' },
          extra: [],
          url: null,
        };
      }
      if (data) {
        store.dispatch('preferences/setGlobalError', data);
      }
    });
    if (!tokenAlreadySent) tokensSent.push({ token, user });
  };

  isSupported().then((hasSupport) => {
    if (hasSupport) {
      const messaging = getMessaging(firebaseApp);
      const unsubStorePush = store.subscribe((mutation) => {
        if (mutation.type === 'preferences/pushPermission') {
          if (mutation.payload) {
            unsubStorePush();
            onIdTokenChanged(auth, (user) => {
              getToken(messaging).then((token) => {
                if (user) {
                  if (store.getters['authToken/isAuth']) {
                    sendToken(token, user);
                  } else {
                    const unsubStoreAuth = store.subscribe((mutation2) => {
                      // If user, wait until he's auth server side
                      if (mutation2.type === 'authToken/auth' && mutation2.payload === true) {
                        sendToken(token, user);
                        unsubStoreAuth();
                      }
                    });
                  }
                } else {
                  sendToken(token);
                }
              }).catch((e) => {
                if (e.code !== 'messaging/permission-blocked') {
                  $bugsnag.notify(e);
                }
              });
            });
          }
        }
      });
    } else {
      $bugsnag.notify(new Error('Firebase messaging not supported'));
    }
  }).catch((e) => {
    $bugsnag.notify(e);
  });

  setFirebase({ signOut: () => signOut(auth) });

  inject('firebase', {
    signOut: () => signOut(auth),
    signInWithEmailAndPassword: (email, password) => signInWithEmailAndPassword(auth, email, password),
    GoogleAuthProvider,
    FacebookAuthProvider,
    signInWithPopup: (provider) => signInWithPopup(auth, provider),
    onAuthStateChanged: (callback) => onAuthStateChanged(auth, callback),
    onIdTokenChanged: (callback) => onIdTokenChanged(auth, callback),
    getIdToken: (forceRefresh = false) => getIdToken(auth.currentUser, forceRefresh),
  });
};
