// Routing Helpers (http://localhost:8012/doc/front/libraries/routing_helpers)

import * as apiUtility from '../api/public/utility';

let $from = null;
let $to = null;
let $router = null;
let $store = null;
let $appNav = null;

// META
let $isPublic = null;
let $isPartial = null;
let $isPrivate = null;

// STORE
let $isAuth = null;
let $userSecured = null;
let $getUser = null;

export function initHelpers(data, appNav = false) {
  $from = data.from;
  $router = data.router;
  if ((!data.to.name || !data.to.path)) {
    $to = { ...$router.resolve(data.to).resolved, matched: null };
  } else {
    $to = data.to;
  }

  // Meta is a array when coming from middleware
  if (Array.isArray($to.meta) && $to.meta.length) {
    $to.meta = $to.meta[0];
  }

  $to.params = { ...data.to.params, ...$to.params };
  $appNav = appNav;

  // META
  $isPublic = $to.meta.publicOnly;
  $isPartial = $to.meta.partial;
  $isPrivate = $to.meta.private;

  // STORE
  $store = data.store;
  $isAuth = $store.getters['authToken/isAuth'];
  $userSecured = $store.getters['preferences/userSecured'];
  $getUser = $store.getters['preferences/getUser'];
}

// GETTERS
export function getToUrl() {
  return $to;
}

// HELPERS
export function routingValidation() {
  if ($isPrivate && !$userSecured) {
    const params = ($to.name === 'DetailUpload') ? {
      admin: $to.params.admin, place: $to.params.place, id: $to.params.id, toDetailUpload: true,
    } : {};
    $to = {
      ...$router.resolve({
        name: 'Login',
        query: { redirect: $to.fullPath, token: $to.query.token },
        params,
      }).resolved,
      matched: null,
      replace: true,
    };
  } else if ($isAuth && $userSecured && ($isPublic || $isPartial)) {
    if ($getUser.reactive) {
      $to = { ...$router.resolve({ name: 'WelcomeBack' }).resolved, matched: null, replace: true };
    } else {
      $to = { ...$router.resolve({ name: 'Account' }).resolved, matched: null, replace: true };
    }
  } else if ($isAuth && !$userSecured && $isPublic) {
    $to = { ...$router.resolve({ name: 'Login' }).resolved, matched: null, replace: true };
  } else if (
    !($from?.name === 'Login' && $from?.params.toDetailUpload)
    && ($to.name === 'DetailUpload' && ($from?.name !== 'detail-list' && $from?.name !== 'detail'))
  ) {
    $to = {
      ...$router.resolve({
        name: 'detail-list',
        params: { admin: $to.params.admin, place: $to.params.place, id: $to.params.id },
      }).resolved,
      matched: null,
      replace: true,
    };
  }
  $to.params.appNav = $appNav;
}

export function handleOffline() {
  return new Promise((resolve) => {
    if ('caches' in window) {
      caches.open('ADJ-runtime').then((cache) => {
        cache.keys().then((keys) => {
          const test = new RegExp(`.*/_nuxt/${getToUrl().name}_.*.js`);
          if (!keys.some((request) => request.url.match(test))) {
            $to = { ...$router.resolve({ name: 'offline', query: { redirect: $to.fullPath } }).resolved, matched: null };
          }
          resolve();
        });
      });
    } else {
      resolve();
    }
  });
}

export function notSameRoute() {
  return $from?.fullPath !== $to?.fullPath;
}

export async function noDialog() {
  if ($to.params.noDialog) {
    $store.dispatch('dialogs/removeAllCloseFn');
  } else if (await $store.dispatch('dialogs/closeLast')) {
    if (!$appNav) {
      const title = document.title;
      document.title = '';
      document.title = title;
      window.$nuxt.$newRouter.pushState(null, $from?.name, $from?.path);
    }
    return false;
  }
  return true;
}

export async function convertOldAdj() {
  const newRoute = await apiUtility.convertUrlOldAdj({ url: $to.path }).then((res) => res.url).catch(() => null);
  if (newRoute) {
    $to = {
      ...$router.resolve({
        path: newRoute,
      }).resolved,
      matched: null,
      replace: true,
    };
  }
}
