/* eslint-disable no-underscore-dangle */
import Vue from 'vue';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';

let bugsnagClient;

export default ({ env }, inject) => {
  if (!Bugsnag._client) {
    bugsnagClient = Bugsnag.start({
      apiKey: env.BUGSNAG_API_KEY,
      enabledReleaseStages: ['production'],
      plugins: [new BugsnagPluginVue()],
    });
    Bugsnag.getPlugin('vue').installVueErrorHandler(Vue);
  } else {
    bugsnagClient = Bugsnag._client;
  }
  if (process.client) window.Bugsnag = bugsnagClient;
  inject('bugsnag', bugsnagClient);
};
