const cookiePrefix = '';

/**
 * Ecrit un cookie
 * @param {String} name
 * @param {String} value
 * @param {Number} days
 * @param {Boolean} secure
 */
export function setCookie(name, value, days, secure) {
  const expire = new Date();
  expire.setDate(expire.getDate() + days);
  document.cookie = `${cookiePrefix + name}=${escape(value)}; expires=${expire.toGMTString()}; path=/${secure ? '; secure' : ''};`;
  return true;
}

/**
 * Lis un cookie
 * @param {String} name
 */
export function getCookie(name) {
  if (document.cookie.length > 0) {
    name = cookiePrefix + name;

    let start = document.cookie.indexOf(`${name}=`);
    let pos = start + name.length + 1;
    let end;
    if (start !== 0) {
      start = document.cookie.indexOf(`; ${name}=`);
      pos = start + name.length + 3;
    }
    if (start !== -1) {
      start = pos;
      end = document.cookie.indexOf(';', start);

      if (end === -1) {
        end = document.cookie.length;
      }
      return unescape(document.cookie.substring(start, end));
    }
  }
  return false;
}
