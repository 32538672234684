/* eslint-disable no-use-before-define */ // Circular call
import axios from 'axios';
import {
  addGeoQueue,
  addPrivateQueue,
  addPublicQueue,
  buildHeaders,
  clearGeoQueue,
  clearPrivateQueue,
  clearPublicQueue,
  commitError,
  geoHeader,
  getRouter,
  getStore,
  getBugsnag,
  handleUrlPath,
  jwtHeaders,
  launchPrivateQueue,
  loginHeader,
  firebaseLogout,
} from './helpers';

// lists of requests Canceler
const cancelers = [];
const maxTry = 3;
const regexIsLogin = new RegExp('.*/(api/login|account/restoration)', 'g');
export const scopePublic = 'public';
export const scopePrivate = 'private';
export const scopeGeonames = 'geonames';

/**
 * Get Headers construit avec Authorization Bearer
 * @param {scopePublic|scopePrivate|scopeGeonames} scope Call public, private or geonames api ?
 * @param {any} headers Headers additionnels à ajouter à la requête
 * @returns {Promise<any>} Headers avec Authorization
 */

export async function getHeaders(scope, headers = {}) {
  if (scope === scopeGeonames) {
    const geoToken = getStore().getters['map/getGeoToken'];

    if (geoToken.access && geoToken.exp > Date.now()) {
      return new Promise((resolve) => resolve(buildHeaders(geoToken.access, headers)));
    }
  }

  let token = null;
  const { access, exp, isPublic } = getStore().getters['authToken/token'];
  if (access && exp > Date.now()) {
    if (scope === scopePublic || (scope === scopePrivate && !isPublic)) {
      return new Promise((resolve) => resolve(buildHeaders(access, headers)));
    }
    if (scope === scopePrivate) {
      token = await loginHeader(access);
    } else if (scope === scopeGeonames) {
      token = await geoHeader(access);
    }
  } else {
    const refresh = scope === scopePublic || (scope === scopePrivate && !isPublic);
    token = await jwtHeaders(refresh, scope !== scopePrivate && isPublic, scope !== scopePrivate);

    if (scope === scopeGeonames) {
      if (token) {
        token = await geoHeader(token);
      } else {
        addPublicQueue({
          promise: () => geoHeader(getStore().getters['authToken/token'].access),
          resolve: () => {},
          reject: () => {},
        });
      }
    }
  }
  if (!token) return token;
  return buildHeaders(token, headers);
}

/**
 * Execute une request axios
 * @param {} context Instance du composant ayant appelé l'api
 * @param {scopePublic|scopePrivate|scopeGeonames} scope Call public, private or geonames api ?
 * @param {AxiosRequestConfig} request
 * @param {Function} callback what to do on success
 * @param {number} nbTried
 */
async function execRequest(context, scope, request, nbTried = 0) {
  request.url = handleUrlPath(request.url, scope);
  request.headers = await getHeaders(scope, request.headers);
  if (request.headers === false) {
    return new Promise((resolve, reject) => {
      const callback = { promise: () => execRequest(context, scope, request, nbTried), resolve, reject };
      switch (scope) {
        case scopePublic:
          addPublicQueue(callback);
          break;
        case scopePrivate:
          addPrivateQueue(callback);
          break;
        case scopeGeonames:
          addGeoQueue(callback);
          break;
        default:
          break;
      }
    });
  }

  // Bad params if has empty array or if has array with null/undefined values
  const checkParams = (params) => {
    const hasBadParams = Object.values(params)
      .some((value) => (Array.isArray(value) && (value.length === 0 || value.some((item) => item === null || item === undefined))));

    if (hasBadParams) {
      getBugsnag().notify(new Error(`request has bad params : ${JSON.stringify(params)}`));
    }
  };

  if (request.params) {
    checkParams(request.params);
  }

  return axios.request(request)
    .then((res) => handleResponse(res, request))
    .catch((error) => {
      if (axios.isCancel(error)) {
        error.cancel = true;
        error.message = `Request to "${request.url}" was canceled`;
      }
      nbTried += 1;
      return handleErrors(context, scope, error, true, request, nbTried);
    });
}

/**
 * Gère la réponse pour récupérer les informations de token
 * Retourne la réponse sans les informations de token
 * @param {any} response
 * @param {AxiosRequestConfig} request
 * @returns {any} data sans les informations de token
 */
async function handleResponse(response, request) {
  const isLogin = request.url.match(regexIsLogin);
  if (isLogin) {
    const tokenData = {
      access: response.data.access_token,
      exp: response.data.expires_in,
      isPublic: false,
    };
    await getStore().dispatch('authToken/setToken', tokenData);
    launchPrivateQueue();
    // Delete des informations de token
    ['access_token', 'expires_in', 'refresh_token', 'token_type'].forEach((k) => delete response.data[k]);
  }
  return response.data;
}

/**
 * Gère les cas d'erreur blocantes : auth expiré, forbidden, pas de connexion ou serveur incapable de répondre
 * Retry si possible
 * @param {} context Instance du composant ayant appelé l'api
 * @param {scopePublic|scopePrivate|scopeGeonames} scope Call public, private or geonames api ?
 * @param {Object} error
 * @param {bool} retry to retry the request
 * @param {AxiosRequestConfig} request request to retry
 * @param {any} nbTried nbTried of request
 */
export async function handleErrors(context, scope, error, retry = true, request, nbTried = 0) {
  const clearQueue = () => {
    switch (scope) {
      case scopePublic:
        clearPublicQueue();
        break;
      case scopePrivate:
        clearPrivateQueue();
        break;
      case scopeGeonames:
        clearGeoQueue();
        break;
      default:
        break;
    }
  };

  if (error.cancel) {
    throw error;
  }

  const userSecured = getStore().getters['preferences/userSecured'];

  if (!error.response) {
    clearQueue();
    if (userSecured) {
      firebaseLogout();
    } else {
      getStore().dispatch('authToken/setIsAuth', false);
    }
    getStore().dispatch('preferences/setGlobalError', {
      env: 'http',
      fields: { 500: 'default' },
      extra: [],
      url: null,
    });
    throw error;
  }

  if (error.response.status === 401) {
    switch (scope) {
      case scopePublic:
        await getStore().dispatch('authToken/resetToken');
        break;
      case scopePrivate:
        await getStore().dispatch(`authToken/${nbTried === 2 ? 'resetAuth' : 'resetToken'}`);
        break;
      case scopeGeonames:
        await getStore().dispatch('map/resetGeoToken');
        break;
      default:
        break;
    }

    // maxTry, throw error & reset tried number
    if ((nbTried >= maxTry) || !retry) {
      clearQueue();
      if (scope === scopePrivate) {
        if (userSecured) {
          firebaseLogout();
        } else {
          getStore().dispatch('authToken/setIsAuth', false);
        }
      }
      throw error;
    }
    return execRequest(context, scope, request, nbTried);
  }

  if (error.response.status === 503) {
    getRouter().replace('/maintenance');
    clearQueue();
    throw error;
  }

  if (error.response.status === 403 || error.response.status >= 500) {
    // ATTENTION
    // 403 default n'est pas gérer
    // Actuellement seul l'api de restoration peut la renvoyer (cas improbable)
    // Si nécessaire il faudra vérifier le code erreur (user_deleted || user_blocked)

    const status = error.response.status === 403 ? 403 : 500;
    getStore().dispatch('preferences/setGlobalError', {
      env: 'http',
      fields: { [status]: error.response.data.error ?? 'default' },
      extra: error.response.data.extra ?? [],
      url: null,
    });
    clearQueue();
    throw error;
  }

  if (error.response.status === 404) {
    error.response.data = {
      env: 'http',
      errors: { 404: error.response.data.error ?? 'default' },
      extra: [],
      url: null,
    };
  }

  // 400 & 404 error only
  commitError(context, error);
  throw error;
}

/**
 * GET wrapper, gère l'auth JWT
 * @param {} context Instance du composant ayant appelé l'api
 * @param {scopePublic|scopePrivate|scopeGeonames} scope Call public, private or geonames api ?
 * @param {string} url Relative ou absolue
 * @param {any} params Params à envoyer avec la requête
 * @param {any} headers Headers additionnels à ajouter à la requête
 * @param {boolean} cancelable Permet d'annuler une requête en cas de doublon
 * @returns {Promise<any>} Promise de la requête
 */
export function get(context, scope, url, params = {}, headers = {}, cancelable = false) {
  const request = {
    method: 'GET', url, params, headers,
  };

  // Request is cancelable if new one is sent before this one finish
  if (cancelable) {
    // A previous request is cancelable, cancel it
    if (cancelers[scope + url]) {
      cancelers[scope + url]();
    }

    // Save the Canceler to be able to cancel it if needed
    request.cancelToken = new axios.CancelToken((cancel) => {
      cancelers[scope + url] = cancel;
    });
  }

  return execRequest(context, scope, request);
}

/**
 * OPTIONS wrapper, gère l'auth JWT
 * @param {} context Instance du composant ayant appelé l'api
 * @param {scopePublic|scopePrivate|scopeGeonames} scope Call public, private or geonames api ?
 * @param {string} url Relative ou absolue
 * @param {any} data Data à envoyer avec la requête
 * @param {any} headers Headers additionnels à ajouter à la requête
 * @returns {Promise<any>} Promise de la requête
 */
export function options(context, scope, url, data = {}, headers = {}) {
  const request = {
    method: 'OPTIONS', url, data, headers,
  };

  return execRequest(context, scope, request);
}

/**
 * POST wrapper, gère l'auth JWT
 * @param {} context Instance du composant ayant appelé l'api
 * @param {scopePublic|scopePrivate|scopeGeonames} scope Call public, private or geonames api ?
 * @param {string} url Relative ou absolue
 * @param {any} data Data à envoyer avec la requête
 * @param {boolean} withCredentials Ajoute ce paramètre à la requête
 *    (nécessaire pour envoyer les cookies et les sessionId au back)
 * @param {any} headers Headers additionnels à ajouter à la requête
 * @returns {Promise<any>} Promise de la requête
 */
export function post(context, scope, url, data = {}, withCredentials = false, headers = {}) {
  const request = {
    method: 'POST', url, data, headers, withCredentials,
  };

  return execRequest(context, scope, request);
}

/**
 * PUT wrapper, gère l'auth JWT
 * @param {} context Instance du composant ayant appelé l'api
 * @param {scopePublic|scopePrivate|scopeGeonames} scope Call public, private or geonames api ?
 * @param {string} url Relative ou absolue
 * @param {any} data Data à envoyer avec la requête
 * @param {any} headers Headers additionnels à ajouter à la requête
 * @returns {Promise<any>} Promise de la requête
 */
export function put(context, scope, url, data = {}, headers = {}) {
  const request = {
    method: 'PUT', url, data, headers,
  };

  return execRequest(context, scope, request);
}

/**
 * PATCH wrapper, gère l'auth JWT
 * @param {} context Instance du composant ayant appelé l'api
 * @param {scopePublic|scopePrivate|scopeGeonames} scope Call public, private or geonames api ?
 * @param {string} url Relative ou absolue
 * @param {any} data Data à envoyer avec la requête
 * @param {any} headers Headers additionnels à ajouter à la requête
 * @returns {Promise<any>} Promise de la requête
 */
export function patch(context, scope, url, data = {}, headers = {}) {
  const request = {
    method: 'PATCH', url, data, headers,
  };

  return execRequest(context, scope, request);
}

/**
 * DELETE wrapper, gère l'auth JWT
 * @param {} context Instance du composant ayant appelé l'api
 * @param {scopePublic|scopePrivate|scopeGeonames} scope Call public, private or geonames api ?
 * @param {string} url Relative ou absolue
 * @param {any} params Params à envoyer avec la requête
 * @param {boolean} withCredentials Ajoute ce paramètre à la requête
 *    (nécessaire pour envoyer les cookies et les sessionId au back)
 * @param {any} headers Headers additionnels à ajouter à la requête
 * @returns {Promise<any>} Promise de la requête
 */
export function del(context, scope, url, params = {}, withCredentials = false, headers = {}) {
  const request = {
    method: 'DELETE', url, params, headers, withCredentials,
  };

  return execRequest(context, scope, request);
}
