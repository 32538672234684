export const state = () => ({
  position: 1,
  history: [],
  routeLoading: false,
});

export const getters = {
  getPosition: (state) => state.position,
  getHistory: (state) => state.history,
  getRouteLoading: (state) => state.routeLoading,
};

export const actions = {
  setPosition({ commit }, payload) {
    commit('setPosition', payload);
  },
  addPosition({ commit, state }) {
    commit('setPosition', state.position + 1);
  },
  removePosition({ commit, state }) {
    commit('setPosition', state.position - 1);
  },
  resetHistory: ({ commit }) => {
    commit('resetHistory');
  },
  addHistory: ({ commit }, payload) => {
    commit('addHistory', payload);
  },
  removeLastHistory({ commit }) {
    commit('removeLastHistory');
  },
  setRouteLoading({ commit }, payload) {
    commit('setRouteLoading', payload);
  },
};

export const mutations = {
  setPosition: (state, payload) => (state.position = payload),
  resetHistory: (state) => (state.history = []),
  addHistory: (state, payload) => (state.history.push(payload)),
  removeLastHistory: (state) => state.history.pop(),
  setRouteLoading: (state, payload) => (state.routeLoading = payload),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
