export class LocationAttribute {
  /** @type {Object} */
  id = null;

  /** @type {String} */
  alias = null;

  /** @type {String} */
  name = null;

  /** @type {String} */
  father = null;

  /**
   * Initialise les attributs
   * @param {Object} item
   */
  constructor(item) {
    if (typeof item.id === 'string' || typeof item.id === 'number') {
      this.id = item.id;
    } else if (item.type === 4) {
      this.id = this.sub.id;
    } else if (item.type === 3) {
      this.id = this.city.id;
    } else if (item.type === 2) {
      this.id = this.admin.id;
    } else if (item.type === 1) {
      this.id = this.country.id;
    }

    if (typeof item.alias === 'string') {
      this.alias = item.alias;
    }

    if (typeof item.name === 'string') {
      this.name = item.name;
    }

    if (typeof item.father === 'string') {
      this.father = item.father;
    }
  }
}

/**
 * Type = 1 : Pays
 * Type = 2 : Zone administrative
 * Type = 3 : Ville
 * Type = 4 : Point d'intérêt
 */
export default class {
  /** @type {LocationAttribute} */
  admin = null;

  /** @type {LocationAttribute} */
  city = null;

  /** @type {LocationAttribute} */
  country = null;

  /** @type {{lat: Number, lon: Number}} */
  location = null;

  /** @type {LocationAttribute} */
  sub = null;

  /** @type {Number} */
  type = 0;

  /** @type {Number} */
  EARTH_RADIUS = 6378137;

  /** @type {String} */
  inputValue;

  /** @type {String} */
  inputText;

  /**
   * Search by geographical coordinates
   * Useful to differentiate it from a search by city (wording, icon...)
   * @type {Boolean}
   */
  geoSearch = false;

  /**
   * Geolocation of the user
   * Useful for the wakeUp method in List.vue
   * @type {Boolean}
   */
  userLocation = false;

  /**
   * Initialise les attributs
   * @param {Object} item
   */
  constructor(item) {
    if (item.admin === Object(item.admin)) {
      this.admin = new LocationAttribute(item.admin);
    }

    if (item.city === Object(item.city)) {
      this.city = new LocationAttribute(item.city);
    }

    if (item.country === Object(item.country)) {
      this.country = new LocationAttribute(item.country);
    }

    if (item.location === Object(item.location)) {
      this.location = item.location;
    }

    if (item.sub === Object(item.sub)) {
      this.sub = new LocationAttribute(item.sub);
    }

    if (typeof item.type === 'number') {
      this.type = item.type;
    }

    if (item.geoSearch) {
      this.geoSearch = true;
    }
    this.inputValue = this.getInputValue();
    this.inputText = this.getInputText();

    if (item.userLocation) {
      this.userLocation = true;
    }
  }

  /**
   * Contruit l'input-value pour l'autocomplete'
   */
  getInputValue() {
    if (this.geoSearch) {
      return 'geo';
    }
    if (this.type === 4 || this.type === 3) {
      return this.city.alias;
    }
    if (this.type === 2) {
      return this.admin.alias;
    }
    if (this.type === 1) {
      return this.country.alias;
    }
    return null;
  }

  /**
   * Contruit l'input-text pour l'autocomplete'
   */
  getInputText() {
    if (this.type === 4 || this.type === 3) {
      return this.city.name;
    }
    if (this.type === 2) {
      return this.admin.name;
    }
    if (this.type === 1) {
      return this.country.name;
    }
    return null;
  }

  /**
   * Contruit le label de la localisation pour un autocomplete
   */
  getLabel() {
    if (this.type === 4 || this.type === 3) {
      return this.city.name;
    }

    if (this.type === 2) {
      return this.admin.name;
    }

    if (this.type === 1) {
      return this.country.name;
    }
    return null;
  }

  /**
   * Contruit le sous-label de la localisation pour un autocomplete
   */
  getSublabel() {
    if (this.type === 4 || this.type === 3) {
      if (this.admin) {
        return this.admin.name;
      }

      if (this.country) {
        return this.country.name;
      }
    }

    if (this.type === 2) {
      return this.country.name;
    }

    if (this.type === 1) {
      return '';
    }
    return null;
  }

  /**
   * Contruit l'icon de la localisation pour un autocomplete
   */
  getIcon() {
    if (this.geoSearch) {
      return 'my_location';
    }

    if (this.type === 4) {
      return 'place';
    }

    if (this.type === 3) {
      return 'location_city';
    }

    if (this.type === 2) {
      return 'public';
    }

    if (this.type === 1) {
      return 'flag';
    }
    return null;
  }

  /**
   * Détermine si la localisation est représentable par un point ou non
   */
  isPointable() {
    return this.type === 3 || this.type === 4;
  }

  getDistance(loc) {
    if (!loc) {
      return null;
    }

    const aLat = (this.location.lat * Math.PI) / 180;
    const aLon = (this.location.lon * Math.PI) / 180;
    const bLat = (loc.location.lat * Math.PI) / 180;
    const bLon = (loc.location.lon * Math.PI) / 180;
    return Math.acos(Math.sin(aLat) * Math.sin(bLat)
            + Math.cos(aLat) * Math.cos(bLat)
            * Math.cos(aLon - bLon)) * this.EARTH_RADIUS;
  }
}
