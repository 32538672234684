export default {
  methods: {
    meta: (metas) => metas.flatMap((meta) => [
      {
        hid: meta.hid,
        name: meta.name,
        content: meta.content,
      },
      {
        hid: `og:${meta.hid}`,
        name: `og:${meta.name}`,
        content: meta.content,
      },
      {
        hid: `twitter:${meta.hid}`,
        name: `twitter:${meta.name}`,
        content: meta.content,
      },
    ]),
  },
};
