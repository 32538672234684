let timeoutId = null;
const routeHome = ['search', 'home'];
const snackLeave = {
  options: {
    timeout: 2000,
  },
  content: 'Appuyez deux fois pour quitter l\'application',
};

function intNavWatch() {
  const { $newRouter, $store } = window.$nuxt;
  window.addEventListener('popstate', (e) => {
    clearTimeout(timeoutId);
    $store.dispatch('router/removePosition');
    // Go to the first page of history
    if (e.state?.goFirst) {
      $newRouter.go(-($store.getters['router/getPosition'] - 1));
    // Display exit snackbar
    } else if (e.state?.askExit) {
      $store.dispatch('router/setPosition', 1);
      $store.dispatch('snackbar/initSnackbar', snackLeave);
      timeoutId = setTimeout(() => {
        $newRouter.replaceState({ askExit: true }, 'Recherche', '/recherche');
        $newRouter.pushState({ ready: true }, 'Recherche', '/recherche');
      }, 2000);
    }
  });
}

export default function initNavPwa() {
  const {
    $route, $router, $newRouter, $store,
  } = window.$nuxt;
  const context = { ...$router.resolve({ name: 'search' }).resolved, matched: null };
  $store.dispatch('router/addHistory', context);
  $newRouter.replaceState({ askExit: true }, 'Recherche', context.path);
  $newRouter.pushState({ ready: true }, 'Recherche', context.path);
  // if not home keep current page
  if (!routeHome.includes($route.name)) {
    $newRouter.pushState({}, document.title, $route.path);
  }
  intNavWatch();
}
