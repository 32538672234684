export const state = () => ({
  closeFn: [],
});

export const getters = {
  getAll: (state) => state.closeFn,
};

export const actions = {
  addCloseFn({ commit, state }, payload) {
    commit('closeFn', [...state.closeFn, payload]);
  },
  removeLastCloseFn({ commit }) {
    commit('popLastCloseFn');
  },
  removeAllCloseFn({ commit }) {
    commit('closeFn', []);
  },
  closeLast({ state }) {
    const last = state.closeFn.slice(-1)[0];
    if (typeof last === 'function') {
      last();
      return true;
    }
    return false;
  },
};

export const mutations = {
  closeFn: (state, payload) => (state.closeFn = payload),
  popLastCloseFn: (state) => state.closeFn.pop(),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
