/* eslint-disable import/prefer-default-export */
import { options, get } from './http';

export function ping(context) {
  return options(context, 'ping');
}

export function convertUrlOldAdj(data) {
  return get(null, 'convert/url', data);
}
