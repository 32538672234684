
export default {
  name: 'SMicrodatas',
  functional: true,
  props: {
    jsonld: {
      type: Object,
      required: true,
    },
  },
  render(createElement, { props }) {
    const stringifiedMicrodatas = JSON.stringify(props.jsonld, null, 2);
    return createElement(
      'script', {
        attrs: {
          type: 'application/ld+json',
        },
        domProps: {
          innerHTML: stringifiedMicrodatas,
        },
      },
    );
  },
};
