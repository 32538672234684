import Vue from 'vue';

// directive globale `v-focus`
Vue.directive('focus', {
  // Quand l'élément lié est inséré dans le DOM...
  inserted(el, binding) {
    if (binding.expression && !binding.value) {
      return;
    }
    el.focus();
  },
  // Quand l'élément et ses enfants sont mis à jour
  componentUpdated(el, binding) {
    if (binding.value && !binding.oldValue) {
      el.focus();
    }
  },
});
