const xsmall = 600;
const small = 960;
const medium = 1264;
const large = 1904;
const smallHeight = 600;

const toolbarSmall = 56;
const toolbar = 64;

export const state = () => ({
  windowWidth: 0,
  windowHeight: 0,
  hasHover: true,
  isMobile: false,
});

export const getters = {
  getWindowWidth: (state) => state.windowWidth,
  getWindowHeight: (state) => state.windowHeight,

  getXSmallSize: () => xsmall,
  getSmallSize: () => small,
  getMediumSize: () => medium,
  getLargeSize: () => large,
  getSmallHeight: () => smallHeight,

  isXSmall: (state) => state.windowWidth < xsmall,
  isSmall: (state) => state.windowWidth >= xsmall,
  isMedium: (state) => state.windowWidth >= small,
  isLarge: (state) => state.windowWidth >= medium,
  isXLarge: (state) => state.windowWidth >= large,
  isHSmall: (state) => state.windowHeight <= smallHeight,

  isStrictSmall: (state) => state.windowWidth <= small && state.windowWidth > xsmall,
  isStrictMedium: (state) => state.windowWidth <= medium && state.windowWidth > small,
  isStrictLarge: (state) => state.windowWidth <= large && state.windowWidth > medium,
  isStrictXLarge: (state) => state.windowWidth > large,

  isWGte: (state) => (breakpoint) => state.windowWidth >= breakpoint,
  isWLte: (state) => (breakpoint) => state.windowWidth <= breakpoint,
  isHGte: (state) => (breakpoint) => state.windowHeight >= breakpoint,
  isHLte: (state) => (breakpoint) => state.windowHeight <= breakpoint,

  getScreen: (state) => {
    if (state.windowWidth <= xsmall) {
      return 'xsmall';
    }

    if (state.windowWidth <= small) {
      return 'small';
    }

    if (state.windowWidth <= medium) {
      return 'medium';
    }

    if (state.windowWidth <= large) {
      return 'large';
    }

    return 'xlarge';
  },

  getToolbarSize: (state) => {
    if (state.windowWidth < small) {
      return toolbarSmall;
    }
    return toolbar;
  },

  isPortrait: (state) => state.windowWidth <= state.windowHeight,
  isLandscape: (state) => state.windowWidth > state.windowHeight,

  getHasHover: (state) => state.hasHover,
  getIsMobile: (state) => state.isMobile,
};

export const actions = {
  updateWindowWidth: ({ commit }, payload) => commit('updateWidth', payload),
  updateWindowHeight: ({ commit }, payload) => commit('updateHeight', payload),
  updateHasHover: ({ commit }, payload) => commit('updateHover', payload),
  updateIsMobile: ({ commit }, payload) => commit('updateMobile', payload),
};

export const mutations = {
  updateWidth: (state, payload) => (state.windowWidth = payload),
  updateHeight: (state, payload) => (state.windowHeight = payload),
  updateHover: (state, payload) => (state.hasHover = payload),
  updateMobile: (state, payload) => (state.isMobile = payload),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
