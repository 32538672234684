import Vue from 'vue';
import {
  units,
  numberFormat,
  dateTimeFormatAgo,
} from '@sopheos/orbjs/format_helpers';

import pluralize from './pluralize';

const dateFormatToFrench = (value, year = true) => {
  const date = new Date(value);

  // If date is not a valid Date object, return an empty string
  if (!(date instanceof Date) || Number.isNaN(date.getTime())) {
    return '';
  }

  let output = Intl.DateTimeFormat('fr-FR', {
    dateStyle: 'short',
  }).format(date);

  if (!year) {
    output = output.slice(0, 5);
  }
  return output;
};

Vue.filter('units', units);
Vue.filter('pluralize', pluralize);
Vue.filter('numberFormat', numberFormat);
Vue.filter('dateTimeFormatAgo', dateTimeFormatAgo);
Vue.filter('dateFormatToFrench', dateFormatToFrench);
