export const state = () => ({
  userUid: null,
  access: '',
  pending: false,
  user: {
    displayName: '',
    email: '',
  },
  confirm: null,
  userNotMatch: false,
});

export const getters = {
  userUid: (state) => state.userUid,
  access: (state) => state.access,
  user: (state) => state.user,
  isPending: (state) => state.pending,
  confirm: (state) => state.confirm,
  getUserNotMatch: (state) => state.userNotMatch,
};

export const actions = {
  setUserUid: ({ commit }, value) => {
    commit('userUid', value);
  },
  reset: ({ commit }) => {
    commit('access', '');
    commit('user', {
      displayName: '',
      email: '',
    });
  },
  setUser: (store, { user, access }) => {
    if (user && access) {
      store.commit('user', {
        displayName: user.displayName,
        email: user.email,
      });
      store.commit('access', access);
    } else {
      store.dispatch('reset');
    }
  },
  setPending: ({ commit }, value) => {
    commit('pending', value);
  },
  setConfirm: ({ commit }, value) => commit('updateConfirm', value),
  setUserNotMatch: ({ commit }, payload) => commit('updateUserNotMatch', payload),
};

export const mutations = {
  userUid: (state, payload) => (state.userUid = payload),
  access: (state, payload) => (state.access = payload),
  user: (state, payload) => (state.user = payload),
  pending: (state, payload) => (state.pending = payload),
  updateConfirm: (state, payload) => (state.confirm = payload),
  updateUserNotMatch: (state, payload) => (state.userNotMatch = payload),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
