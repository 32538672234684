import { getBugsnag } from '../api/helpers';

const storePrefixe = 'ADJ';

let hasSupport = null;

function isSupported() {
  if (process.server || hasSupport !== null) return hasSupport;
  try {
    const key = 'TEST_localStorage';
    localStorage.setItem(key, key);
    localStorage.removeItem(key);
    hasSupport = true;
    return true;
  } catch {
    hasSupport = false;
    getBugsnag().notify(new Error('LocalStorage not supported'));
    return false;
  }
}

/**
 * @param {string} field
 */
export function get(field) {
  if (!isSupported()) return null;
  try {
    const data = localStorage.getItem(`${storePrefixe}-${field}`);
    return JSON.parse(data);
  } catch (error) {
    getBugsnag().notify(error);
    return null;
  }
}

/**
 * @param {string} field
 * @param {any} data
 */
export function set(field, data) {
  if (isSupported()) {
    try {
      localStorage.setItem(`${storePrefixe}-${field}`, JSON.stringify(data));
    } catch (error) {
      getBugsnag().notify(error);
    }
  }
}

/**
 * @param {*} field
 */
export function remove(field) {
  if (isSupported()) {
    try {
      localStorage.removeItem(`${storePrefixe}-${field}`);
    } catch (error) {
      getBugsnag().notify(error);
    }
  }
}
