const state = () => ({
  preset: false,
  tmpPayload: null,
  show: false,
  options: {},
  content: '',
  button: {},
  // Nuxt error => closeCallback: () => {},
  closeCallback: null,
  actionCallback: null,
});

const getters = {
  isPreset: (state) => state.preset,
  isVisible: (state) => state.show,
  getOptions: (state) => state.options,
  getContent: (state) => state.content,
  getButton: (state) => state.button,
  getCloseCallback: (state) => state.closeCallback,
  getActionCallback: (state) => state.actionCallback,
};

const actions = {
  initSnackbar: ({ commit }, payload) => commit('presetSnackbar', payload),
  showSnackbar: ({ commit }) => {
    commit('toggleSnackbar', true);
    commit('setSnackbar');
  },
  hideSnackbar: ({ commit }) => commit('toggleSnackbar', false),
  resetSnackbar({ commit }) {
    return new Promise((resolve) => {
      setTimeout(() => {
        commit('unsetSnackbar');
        resolve();
      }, 500);
    });
  },
};

const mutations = {
  presetSnackbar: (state, payload) => {
    state.preset = true;
    state.tmpPayload = payload;
  },
  toggleSnackbar: (state, payload) => {
    state.show = payload;
  },
  setSnackbar: (state) => {
    if (state.tmpPayload.options) {
      state.options = state.tmpPayload.options;
    }
    if (state.tmpPayload.content) {
      state.content = state.tmpPayload.content;
    }
    if (state.tmpPayload.button) {
      state.button = state.tmpPayload.button;
    }
    if (state.tmpPayload.closeCallback) {
      state.closeCallback = state.tmpPayload.closeCallback;
    }
    if (state.tmpPayload.actionCallback) {
      state.actionCallback = state.tmpPayload.actionCallback;
    }
    state.preset = false;
    state.tmpPayload = null;
  },
  unsetSnackbar: (state) => {
    if (state.show === true) {
      state.show = false;
    }
    state.options = {};
    state.content = '';
    state.button = {};
    state.closeCallback = () => {};
    state.actionCallback = () => {};
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
