import {
  options as optionsWrapper,
  get as getWrapper,
  post as postWrapper,
  patch as patchWrapper,
  put as putWrapper,
  del as delWrapper,
  scopePublic,
} from '../wrapper';

export const options = (context, url, data = {}, headers = {}) => optionsWrapper(context, scopePublic, url, data, headers);

export const get = (context, url, params = {}, headers = {}, cancelable = false) => getWrapper(context, scopePublic, url, params, headers, cancelable);

export const post = (context, url, data = {}, withCredentials = false, headers = {}) => postWrapper(context, scopePublic, url, data, withCredentials, headers);

export const put = (context, url, data = {}, headers = {}) => putWrapper(context, scopePublic, url, data, headers);

export const patch = (context, url, data = {}, headers = {}) => patchWrapper(context, scopePublic, url, data, headers);

export const del = (context, url, params = {}, withCredentials = false, headers = {}) => delWrapper(context, scopePublic, url, params, withCredentials, headers);
