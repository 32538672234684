import { get } from './http';

export function large(data, context) {
  return get(context, 'loc/large', data);
}

export function limited(data, context) {
  return get(context, 'loc/limited', data);
}

export function reverse(data, context) {
  return get(context, 'loc/reverse', data);
}

export function around(data, context) {
  return get(context, 'loc/around', data);
}

export function detail(data, context) {
  return get(context, 'loc/detail', data);
}

export function shape(data, context) {
  return get(context, 'loc/shape', data);
}

export function byType(type, data, context) {
  return get(context, `loc/${type}`, data);
}
