export const state = () => ({
  toggleSide: false,
  sideMenuEnabled: true,
  toolbarEnabled: true,
  sideMenuItems: [
    [
      {
        id: 1,
        title: 'Rechercher',
        link: 'search',
        icon: 'search',
        anonymous: true,
        connected: true,
      },
      {
        id: 2,
        title: 'Ajouter une aire de jeux',
        link: 'addForm',
        icon: 'add_circle_outline',
        anonymous: true,
        connected: true,
      },
      {
        id: 3,
        title: 'Me connecter',
        link: 'Login',
        icon: 'person',
        anonymous: true,
        connected: false,
      },
      {
        id: 4,
        title: "M'inscrire",
        link: 'Register',
        icon: 'person_add',
        anonymous: true,
        connected: false,
      },
      {
        id: 5,
        title: 'Mon compte',
        link: 'Account',
        icon: 'perm_identity',
        anonymous: false,
        connected: true,
      },
      {
        id: 6,
        title: 'Contacter le site',
        link: 'contact',
        icon: 'mail_outline',
        anonymous: true,
        connected: true,
      },
    ],
  ],
});

export const getters = {
  isSideToggled: (state) => state.toggleSide,
  getSideMenuItems: (state) => state.sideMenuItems,
  getSideMenuEnabled: (state) => state.sideMenuEnabled,
  getToolbarEnabled: (state) => state.toolbarEnabled,
  // getFooterMenuItems: state => state.footerMenuItems,
  // getFooterEnabled: state => state.footerEnabled,
};

export const actions = {
  toggleSide: ({ commit, state }) => commit('setSide', !state.toggleSide),
  setSideMenu: ({ commit }, payload) => commit('setSide', payload),
  replaceSideMenuItem: ({ commit }, payload) => commit('setSideMenuItem', payload),
  enableSideMenu: ({ commit }) => commit('setSideMenuEnabled', true),
  disableSideMenu: ({ commit }) => commit('setSideMenuEnabled', false),
  enableToolbar: ({ commit }) => commit('setToolbar', true),
  disableToolbar: ({ commit }) => commit('setToolbar', false),
  // replaceFooterItem: ({ commit }, payload) => commit('setFooterItem', payload),
  // enableFooter: ({ commit }) => commit('setFooter', true),
  // disableFooter: ({ commit }) => commit('setFooter', false),
};

export const mutations = {
  setSide: (state, payload) => (state.toggleSide = payload),
  setSideMenuItem: (state, payload) => {
    if (payload.id) {
      const newMenu = state.sideMenuItems;
      const index = newMenu.findIndex((item) => item.id === payload.id);

      if (index) {
        newMenu[index] = { ...newMenu[index], ...payload };
        state.sideMenuItems = {};
        state.sideMenuItems = newMenu;
      }
    }
  },
  setSideMenuEnabled: (state, payload) => (state.sideMenuEnabled = payload),
  setToolbar: (state, payload) => (state.toolbarEnabled = payload),
  // setFooter: (state, payload) => (state.footerEnabled = payload),
  // setFooterItem: (state, payload) => {
  //   if (payload.id) {
  //     const newMenu = state.footerMenuItems;
  //     const index = newMenu.findIndex(item => item.id === payload.id);

  //     if (index) {
  //       newMenu[index] = { ...newMenu[index], ...payload };
  //       state.footerMenuItems = {};
  //       state.footerMenuItems = newMenu;
  //     }
  //   }
  // },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
