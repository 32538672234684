export const state = () => ({
  pwa: false,
  viewMode: 'list',
  detailFromMap: false,
  user: {},
  pushPermission: null,
  globalError: null,
  dialogAboutOpen: false,
  dialogAboutViewed: false,
  notification: {
    moderation: null,
    favorite: null,
    contribution: null,
    newsletter: null,
  },
  userPrefs: {
    tutoswiper: [],
    darkmode: null,
    tutoblur: null,
  },
  dialogPermissions: null,
  dialogPushInformation: null,
  dialogPending: null,
});

export const getters = {
  isPwa: (state) => state.pwa,
  getViewMode: (state) => state.viewMode,
  getDetailFromMap: (state) => state.detailFromMap,
  getUser: (state) => state.user,
  getGlobalError: (state) => state.globalError,
  isDialogAboutOpen: (state) => state.dialogAboutOpen,
  isDialogAboutViewed: (state) => state.dialogAboutViewed,
  getNotification: (state) => state.notification,
  getUserPrefs: (state) => state.userPrefs,
  userSecured: (state) => !!(state.user && state.user.isSecured),
  getDialogPermissions: (state) => state.dialogPermissions,
  getDialogPushInformation: (state) => state.dialogPushInformation,
  getDarkMode: (state) => state.userPrefs.darkmode,
  getDialogPending: (state) => state.dialogPending,
  getTutoBlur: (state) => state.userPrefs.tutoblur,
};

export const actions = {
  updateViewMode({ commit }, payload) {
    commit('setViewMode', payload);
  },
  updateDetailFromMap({ commit }, payload) {
    commit('setDetailFromMap', payload);
  },
  setPwa({ commit }, payload) {
    commit('pwa', payload);
  },
  askPushPermission({ commit }, payload) {
    commit('pushPermission', payload);
  },
  setUser({ commit }, payload) {
    commit('user', payload);
  },
  setUserAvatar({ commit, state }, { avatar, placeholder = true }) {
    commit('user', {
      ...state.user,
      avatar,
      placeholder,
    });
  },
  setGlobalError({ commit }, payload) {
    commit('globalError', payload);
  },
  setDialogAboutOpen({ commit }, payload) {
    commit('dialogAboutOpen', payload);
  },
  setDialogAboutViewed({ commit }, payload) {
    commit('dialogAboutViewed', payload);
  },
  updateUserPrefs({ commit }, payload) {
    commit('setPrefs', payload);
  },
  updateNotification({ commit }, payload) {
    commit('setNotification', payload);
  },
  updateTutoSwiped({ commit, state }, payload) {
    commit('setUserPrefs', {
      tutoswiper: payload,
      darkmode: state.userPrefs.darkmode,
      tutoblur: state.userPrefs.tutoblur,
    });
  },
  updateDarkMode({ commit, state }, payload) {
    commit('setUserPrefs', {
      tutoswiper: state.userPrefs.tutoswiper,
      darkmode: payload,
      tutoblur: state.userPrefs.tutoblur,
    });
  },
  updateTutoBlur({ commit, state }, payload) {
    commit('setUserPrefs', {
      tutoswiper: state.userPrefs.tutoswiper,
      darkmode: state.userPrefs.darkmode,
      tutoblur: payload,
    });
  },
  setDialogPermissions({ commit }, payload) {
    commit('dialogPermissions', payload);
  },
  setDialogPushInformation({ commit }, payload) {
    commit('dialogPushInformation', payload);
  },
  setDialogPending({ commit }, payload) {
    commit('dialogPending', payload);
  },
};

export const mutations = {
  pwa: (state, payload) => (state.pwa = payload),
  pushPermission: (state, payload) => (state.pushPermission = payload),
  setViewMode: (state, payload) => (state.viewMode = payload),
  setDetailFromMap: (state, payload) => (state.detailFromMap = payload),
  user: (state, payload) => (state.user = payload),
  globalError: (state, payload) => (state.globalError = payload),
  dialogAboutOpen: (state, payload) => (state.dialogAboutOpen = payload),
  dialogAboutViewed: (state, payload) => (state.dialogAboutViewed = payload),
  setNotification: (state, payload) => (state.notification = payload),
  setUserPrefs: (state, payload) => (state.userPrefs = payload),
  setPrefs: (state, payload) => {
    state.notification = payload.notification;
    state.userPrefs = payload.user;
  },
  dialogPermissions: (state, payload) => (state.dialogPermissions = payload),
  dialogPushInformation: (state, payload) => (state.dialogPushInformation = payload),
  dialogPending: (state, payload) => (state.dialogPending = payload),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
